.login-form-card{
  text-align:center;
  align-items: center;
}

.login-form-label-left{
  text-align: left;
  align-items: flex-start;
}

.LoginForm > *{
  margin:0.5em;
}

.login-label{
  font-size: smaller;
}
.login-error{
  color:red;
}

.deleteOnDeployment{
  background-color: #eee;
  margin:0.5em;
  padding:0.5em;
  border:1px solid #737475;
}
.deleteOnDeployment h5{
  margin:0.5em;
}
.tooltip{
  font-size: x-small;
}
.strikethrough{
  text-decoration: line-through;
}