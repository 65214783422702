.not-found-page{
  height:90vh;
  width:100%;
  /* background-image: url("https://images.unsplash.com/photo-1610459716431-e07abcf74230?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1060&q=80");
  background-position: center;
  background-repeat: no-repeat;
  background-size:cover ; */
  display:flex;
  justify-content: center;
  align-items: center;
  box-shadow:inset 
}
.border-left{
  border-left:0.25em solid black;
}
.not-found-number{
  font-size:500%;
  padding:0.5em;
}
.not-found-text{
  font-size:200%;
  padding:1em;
}