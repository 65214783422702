@font-face {
  font-family: 'GreycliffCFRegular';
  src: local('GreycliffCFRegular'), url('./fonts/GreycliffCF-Regular.woff');
}
@font-face {
  font-family: 'GreycliffCFMedium';
  src: local('GreycliffCFMedium'), url('./fonts/GreycliffCF-Medium.woff');
}
@font-face {
  font-family: 'GreycliffCFBold';
  src: local('GreycliffCFBold'), url('./fonts/GreycliffCF-Bold.woff');
}
/* minimal css reset */
/* @font-face {
  font-family:'GreycliffCFBold' ;
  src: local('GreycliffCFBold'),
  url('./fonts/GreycliffCF-Bold.woff');
} */

html {
  /* background: rgb(14,39,70);
  background: linear-gradient(328deg, rgba(14,39,70,1) 0%, rgba(14,39,70,1) 0%, rgba(0,170,175,1) 100%); */
  /* background-color:#859aff; */
  /* border:red 5px; */
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 100vh;
  box-sizing: border-box;
  font-size: 16px;
  /* font-family: 'Open Sans', sans-serif; */
}
header {
  position: sticky;
  top: 0;
  width: 100vw;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'GreycliffCFBold', Arial,
    'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #a9a9a9; */
  overflow-y: auto;
}
.nonBold {
  font-family: 'GreycliffCFRegular', Arial, 'Open Sans', 'Segoe UI', 'Roboto',
    'Oxygen', 'Helvetica Neue', sans-serif;
  font-size: large;
}

.noScroll {
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

a:visited {
  color: #00aaaf;
}
/* end css reset */
/* Parent Styling */
section.page {
  margin: 1em 1em 1em 15.6vw;
  padding-bottom: 1em;
  /* background-color:pink; */
  /* margin:1em; */
  /* max-height:100vh; */
  overflow-y: auto;
  /* width:84vw; */
  /* border:3px solid black; */
}

.headline {
  font-family: 'GreycliffCFRegular', Arial, sans-serif;
  /* font-size:58pt; */
  font-size: 2vh;
  /* letter-spacing: -20px; */
  /* line-height: 55; */
}
.bodycopy,
p,
.bolder-font {
  font-family: 'GreycliffCFBold', Arial, sans-serif;
  /* font-size:14pt; */
  letter-spacing: 0;
  /* line-height: 22; */
}
.subhead,
.projects-and-products-headers-text-color-grey {
  font-family: 'GreycliffCFMedium', Arial, sans-serif;
  font-size: 2vh;
  letter-spacing: 2px;
  padding-bottom:1.0em;
  /* line-height: 24; */
}

.m-a {
  margin: auto !important;
  display: block;
}
.f-center {
  display: flex;
  justify-content: center;
}
.alignCenter {
  align-items: center;
}

.Link:visited {
  color: #5580fd;
  text-decoration: none;
}

.teal-link {
  color: #00aaaf;
}
.white-link {
  color: white !important;
}

/* React Main Display CSS */
/* .main-display{
  display: flex;
} */

/*Start of CSS for Become a Member Component*/
/* .card-container{ */
/* width: 700px; */
/* box-shadow: 1px 1px 3px 3px #737475 */
/* } */

.card-header-orange {
  background-color: #dffd61;
  padding: 0.6em 1em 0.6em 1em;
}

.color-navy {
  color: #0e2746 !important;
}

.card-header-title-style {
  font-weight: bold;
  color: white;
}
.card {
  background-color: #edece0;
  max-height: 80vh;
  overflow-y: hidden;
  margin: 0.5em;
  border-radius: 0 0 50px 0;
}

.card-body {
  border-radius: 0 0 50px 0;
  padding: 1em;
  background-color: #edece0;
  max-height: 80vh;
  overflow-y: hidden;
}
.card-body-report {
  border-radius: 0 0 50px 0;
  /* padding: 1em; */
  background-color: #edece0;
  max-height: 80vh;
  overflow-y: hidden;
  margin-bottom: 1em;
}
.card-body-report > * {
  padding: 0.5em;
}
.detail-card-body {
  width: 100%;
}

.component-header-styles {
  font-weight: bold;
  color: white;
}

.margin-bottom {
  margin-bottom: 1em;
}
.margin-right {
  margin-right: 1em;
}
.margin-top {
  margin-top: 1em;
}
.m1 {
  margin: 1em;
}
.my-1 {
  margin: 0 1em;
}
.borderbox {
  box-sizing: border-box;
}

.shrink {
  transform: scale(0.7);
}

/* cta-buttons */
.cta-button {
  padding: 0.8em;
  /* background-color: #115646; */
  background-color: #0e2746;
  color: white;
  border-radius: 5%;
  text-align: center;
  /* box-shadow: 0 4px 4px -4px black; */
  font-weight: bolder;
  letter-spacing: 1px;
  outline: none;
  border: 0 !important;
  cursor: pointer;
}

/* .cta-button {
  padding: .8em;
  background-color: #5580FD;
  color: white;
  border-radius: 5%;
  text-align: center;
  box-shadow: 0 4px 4px -4px black;
  font-weight: bolder;
  letter-spacing: 1px;
} */

.cta-button4 {
  padding: 0.8em;
  background-color: #0e2746;
  color: white;
  border-radius: 5%;
  font-family: 'GreycliffCFRegular', sans-serif;
  letter-spacing: 2px !important;
  text-align: center;
  border: 0;
  /* box-shadow: 0 4px 4px -4px black; */
  font-weight: bolder;
  letter-spacing: 1px;
}
.cta-button3 {
  padding: 0.3em;
  background-color: #dddddd;
  border-radius: 15px;
  text-align: center;
  padding: 5px 15px 5px 15px;
  border: 0;
  outline: none;
  cursor: pointer;
}

.cta-button2 {
  padding: 0.8em;
  border: 1px solid #5580fd;
  background-color: transparent;
  /* color: #115646; */
  color: #5580fd !important;
  border-radius: 5%;
  text-align: center;
  font-weight: bolder;
  letter-spacing: 1px;
  cursor: pointer;
}
.cta-button5 {
  padding: 0.8em;
  border: 1px solid white;
  background-color: transparent;
  /* color: #115646; */
  /* color:#5580FD !important; */
  border-radius: 5%;
  text-align: center;
  font-weight: bolder;
  letter-spacing: 1px;
  margin-top: 0.25em;
  cursor: pointer;
}
.cta-button6 {
  color: white;
  padding: 0.4em;
  border: 1px solid white;
  background-color: transparent;
  /* color: #115646; */
  /* color:#5580FD !important; */
  border-radius: 5%;
  text-align: center;
  font-weight: bolder;
  letter-spacing: 1px;
  font-size: smaller;
  /* margin-top:0.25em; */
  cursor: pointer;
}
.cta-button5:visited,
.cta-button6:visited {
  color: white;
}
/* .cta-button2 {
  padding: .8em;
  border: 1px solid #FDA941;
  background-color: white;
  color: #115646;
  border-radius: 5%;
  text-align: center;
  font-weight: bolder;
  letter-spacing: 1px;
  outline:none;
  cursor: pointer;
} */

.cta-button2:hover {
  background-color: #5580fd;
  color: white !important;
}

.cta-button:visited {
  color: white;
}
/* end cta buttons */
/*End of CSS for Become a Member Component */

/*Start of CSS for Certification Status Component*/
.card-header-title-style-gray {
  /* color: #115646; */
  font-weight: bold;
  color: white;
  font-family: 'GreycliffCFregular', Arial, sans-serif;
  text-transform: uppercase;
  font-size: 2vh;
  letter-spacing: 9px;
  /* line-height: 24; */
  /* background: rgb(14,39,70); */
  /* background: linear-gradient(328deg, rgba(14,39,70,1) 0%, rgba(14,39,70,1) 0%, rgba(0,170,175,1) 100%); */
}
.card-header-title-style-teal {
  font-weight: bold;
  color: #00aaaf;
  font-family: 'GreycliffCFregular', Arial, sans-serif;
  text-transform: uppercase;
  font-size: 2vh;
  letter-spacing: 1px;
}
.card-header-grey {
  /* background-color:#EDECE0; */
  /* border-bottom:1px solid #EDECE0; */
  background: #00aaaf;
  /* background: linear-gradient(328deg, rgba(14,39,70,1) 0%, rgba(14,39,70,1) 0%, rgba(0,170,175,1) 100%); */
  color: white !important;
  padding: 0.6em 1em 0.6em 1em;
}

.report-subheader {
  background-color: #0e2746;
  color: white;
  font-size: 2.1vh;
}

.display-flex {
  display: flex;
  justify-content: space-between;
}

.flex-space {
  display: flex;
  justify-content: space-around;
}

.flex-center {
  display: flex;
  justify-content: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.remove-text-decoration {
  text-decoration: none;
}
/*End of CSS for Certification Status Component*/

/* reports and detail view CSS */
.detailCardContainer {
  width: 80vw;
  margin: auto;
}
.fourTables {
  display: flex;
}
.fourTables > table {
  border-right: 1px solid black;
  margin: 0.25em;
  padding: 0.25em;
}
.fourTables table tr:nth-child(odd) {
  background-color: #f3f3f3;
}
/* .detailCard{ */
/* max-height:70vh; */
/* } */
.detailImg {
  display: block;
  text-align: center;
  margin: auto;
  max-width: 80%;
}
.detailTable {
  width: 100%;
}
.detailTable th,
.detailTable td {
  padding: 0.2em;
}
.detailTable th {
  font-weight: bolder;
  text-align: right;
}
.detailTable2 {
  width: 60%;
  margin: auto;
}
.detailTable2 th,
.detailTable2 td {
  padding: 0.2em;
  width: 30%;
  /* background:pink; */
}
.detailTable2 th {
  font-weight: bolder;
  text-align: right;
}
.detailButton {
  text-align: center;
  margin: 0.5em auto;
  display: block;
}
.listImgThumbnail {
  padding: 5px;
}
/* .h30{
  height:30vh;
} */

/*Start of CSS for 'Manage Your Projects & Products'*/
.projects-and-products-headers {
  grid-template-columns: auto 50% 30%;
}

.projects-headers {
  grid-template-columns: 5% repeat(11, 8.7%);
}

.events-headers {
  /* brenden original */
  /* grid-template-columns: 20% 10% 6% 6% 14% 14% 14% 15%; */
  grid-template-columns: 20% 10% 8% 8% 6% 14% 14% 15%;
}

.past-events-headers {
  grid-template-columns: 24% 14% 10% 10% 14% 14% 14%;
  /* background-color:#5580FD; */
}

.products-headers {
  /* grid-template-columns: repeat(7, 14.29%); */
  grid-template-columns: 10% 25% 15% 10% 20% 10% 10%;
}
.ceus-headers {
  grid-template-columns: repeat(5, 20%);
}

.professional-headers {
  grid-template-columns: repeat(5, 20%);
}
.renewal-headers {
  grid-template-columns: repeat(4, 25%);
}
.certification-headers {
  grid-template-columns: repeat(6, 16%);
}
.continuing-education-headers {
  grid-template-columns: repeat(6, 16%);
}

.projects-and-products-headers,
.projects-headers,
.events-headers,
.past-events-headers,
.products-headers,
.ceus-headers,
.professional-headers,
.continuing-education-headers,
.certification-headers,
.renewal-headers {
  border: none;
  display: grid;
  /* grid-template-rows: 30px; */
  align-items: center;
  border-bottom: 1px solid #dbdbd9;
}

.overflow-container {
  overflow-y: auto;
  /* min-height: 15vh; */
  max-height: 30vh;
}
/* .overflow-container:last-child{
  border-bottom:0 !important;
} */
/*
.overflow-container.report{
  max-height:90vh;
} */

.report-button {
  margin-right: 10px;
}

/* .projects-and-products-headers-text-color-grey{
  color: #737475;
} */

/*End of CSS for 'Manage Your Projects & Products'*/

/*Start of CSS for Upcoming Training & Events*/
.training-and-events-container {
  border-radius: 10px;
  border: 1px solid #dddddd;
  padding: 1em;
  background-color: #edece0;
}

.upcoming-training-and-events-headers {
  padding: 0.5em;
}

.border-bottom-div-gray {
  border-bottom: 1px solid #dddddd;
}

/* .bolder-font{
  font-weight: 750;
} */

.letter-spacing-button {
  letter-spacing: 1px;
}

.overflow-container-training-and-events {
  overflow-y: auto;
  max-height: 500px;
}
/*End of CSS for Upcoming Training & Events*/

/*Start of CSS for Top Navbar*/
.header-profile-bar-container {
  /* position:sticky;
  top:0; */
  display: flex;
  height: 100%;
  /* flex-direction: row-reverse; */
  justify-content: flex-end;
  /* background-color:#282D31; */
  /* background-color:#0E2746; */
  background: rgb(14, 39, 70);
  background: linear-gradient(
    328deg,
    rgba(14, 39, 70, 1) 0%,
    rgba(14, 39, 70, 1) 0%,
    rgba(0, 170, 175, 1) 100%
  );
  align-items: center;
  /* padding: .5em; */
  /* position:fixed;
  top:0; */
}

.header-align-content {
  align-content: center;
  align-items: center;
  text-align: center;
}

.header-profile-bar-container > div {
  align-items: center;
  padding: 0 2em;
  /* height:100%;  */
}

.header-logo-container {
  /* background-color: #1E2226; */
  background-color: white;
  height: 120px;
  padding: 0.5em;
  /* box-sizing: border-box; */
  /* width:15vw; */
  text-align: center;
}
.header-logo {
  /* padding:20px; */
  /* color:white; */
  color: #0e2746;
  text-decoration: none;
}

.header-user-content {
  margin: 0.5em;
}

.image-cropper {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.profile-pic {
  display: inline;
  margin: 0 auto;
  /* margin-left: -25%; */
  /* height: 100%; */
  /* width: auto; */
}

.margin-left-of-div {
  margin-left: 10px;
}

.username-style {
  color: white;
  font-family: 'GreycliffCFRegular', Arial, sans-serif;
  font-size: 3vh;
  /* letter-spacing: -20px; */
  /* line-height: 24; */
}
/*End of CSS for Top Navbar*/

.card-container-projectapp {
  background-color: white;
}

.card-header-gray {
  /* background-color:#F3F3F3; */
  /* background-color: #EDECE0; */
  border-bottom: 1px solid #edece0;
  padding: 1em;
  /* color: #115646; */
  color: #6e4692;
}

.body-text {
  margin-bottom: 1em;
}

.body-input {
  /* width: 75%; */
  padding: 0.8em;
}
.edit-input {
  background-color: white;
  border: 0.75px solid black;
  padding: 0.5em;
  width: 100%;
}

h3 {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}
.larger {
  font-size: larger;
}

/* .card-body {
  padding: 1em;
} */

/* .negative-margin { */
/* margin-left: -4px; */
/* } */

.top-margin {
  margin-top: 20px;
}

/*Start of CSS for Membership page*/
.flexbox-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flexbox-row-with-space-between {
  display: flex;
  justify-content: space-between;
}

.background-color-white {
  background-color: #edece0;
}

.flex-grow-1 {
  flex-grow: 1;
  flex-basis: 100px;
}

.margin-1em {
  margin: 1em;
}

.margin-left {
  margin-left: 1em;
}
.margin-right {
  margin-right: 1em;
}

.width-100 {
  width: 100%;
}
.width-99 {
  width: 99%;
}

.width-50 {
  width: 50%;
}

.width-60 {
  width: 60%;
}

.width-40 {
  width: 40%;
}

.width-20 {
  width: 20%;
}

.width-30 {
  width: 30%;
}

.width-70 {
  width: 70%;
}

.margin-top-and-bottom {
  margin-top: 1em;
  margin-bottom: 1em;
}

.height-100vh {
  height: 100vh;
}

.height-50-vh {
  height: 50vh;
}

.test-border {
  border: 1px solid red;
}

.height-90-percent {
  height: 90%;
}

.height-80-percent {
  height: 80%;
}

.height-20-percent {
  height: 20%;
}

.height-100-percent {
  height: 100%;
}

.space {
  margin: 1em 0 0 0;
  padding: 0;
}

.orange-icon-color {
  color: #dffd61;
}

.all-around-padding-1em {
  padding: 1em;
}

.orange-line-color {
  padding-bottom: 0.5em;
  border-bottom: 0.5em solid #dffd61;
}

.notificationBar {
  background-color: #edece0;
  margin-bottom: 1em;
}
/*End of CSS for membership page*/

/* Start of NavBar CSS */

.navbar-container {
  width: 15vw;
  height: 100%;
  background-color: #edece0;
  position: fixed;
  top: 0;
}

.topbar {
  background-color: black;
  text-align: center;
  height: fit-content;
  width: 100%;
  color: white;
  padding: 1em;
}

.nav-items {
  width: 100%;
  /* background-color: #F3F3F3; */
  font-size: 14px;
}

.nav-items a {
  width: 100%;
  display: block;
  padding: 0.8em 1em 0.5em 1em;
  text-decoration: none;
  color: #0e2746;
  font-family: 'GreycliffCFBold', Arial, sans-serif;
  font-size: 2vh;
  text-transform: uppercase;
  letter-spacing: 2px;
  /* line-height: 24; */
  /* width: 100%; */
}

.nav-items i {
  margin-right: 5px;
}

.fa-angle-right {
  margin-left: 15px;
}

.nav-items a.active {
  /* background-color: #DDDDDD; */
  border-bottom: #00aaaf 0.45em solid;
  width: 80%;
  font-family: 'GreycliffCFBold';
  /* padding:0.5em; */
  margin-left: 1em;
}

/* End of NavBar CSS */

/* Start of Continuing Education*/
.card-header-orange-with-1em-padding {
  background-color: #ffcf34;
  padding: 1em;
}
/*End of Continuing Education*/

.max-width-40 {
  max-width: 40%;
}
.max-width-60 {
  max-width: 60%;
}

.width-150px {
  width: 150px;
}

/*Start of CSS for Progress Bar*/
/* .background-color-white{
  background-color:white;
} */

.darkgreen-border-div {
  /* border: 1px solid #115646; */
  border: 1px solid #0e2746;
}

.progressbar {
  /* background-color: #115646; */
  background-color: #0e2746;
  color: white;
  text-align: center;
}

.progressbar-flex-size {
  flex: 0 0 30%;
}

.small-flex-size {
  flex: 0 0 55%;
}

.display-inline {
  display: inline-block;
  width: 45% !important;
}

/*End of CSS for progress Bar
*/
