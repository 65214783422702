.admin-container{
  width:100%;
  display: flex;
  justify-content: space-between;
}
textarea{
  max-width:100%;
}
.alert-form-bar{
  margin-top:0.5em;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}

.help-text{
  /* color:#115646; */
  color:#5580FD;
  font-size: smaller;
}

.admin-user-table{
  border-collapse: collapse;
  margin-bottom:1em;
  overflow-y: scroll;
}

.admin-user-row{
  width:100%;
  border-bottom:1px solid #DDD; 
}
.admin-user-row td{
  padding:0.5em 0;
}

.admin-user-row td:last-child{
  text-align: right;
}
