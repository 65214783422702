.profile-Row3{
  display: grid;
  grid-template-columns: 33% 33%  33%;
  grid-template-rows: 45vh;
}
.profile-Row2{
  display: grid;
  grid-template-columns: 33%  66%;
  grid-template-rows: 35vh;
}
.profile-card-marg{
  margin:0.5em;
}
section.card-body{
  padding:0;
}
.profile-card-banner{
  background-color:#F3F3F3;
  height:60%;
  width:100%;
  padding-bottom:0.5em;
  display:flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

/* .card-body{ */
  /* overflow: hidden !important; */
/* } */
.profile-picture{
  height:70%;
  display:block;
  margin:auto;
}
.text-center{
  text-align: center;
  margin:auto;
}
.text-right{
  text-align:right;
  padding-right:0.5em;
}
.italics{
  font-style: italic;
}
.profile-card-table{
  width:100%;
  padding:1em;
  font-size:100%;
}
.profile-card-table td{
  width:50%;
  padding-top:10px;
}
.profile-th{
  color:#737475
}
/* .pi-font-size{
  font-size: 150%;
} */
.profile-certifications{
  margin:1em;
}
.profile-marg{
  margin: 0.5em 0;
}
.profile-certifications-overflow{
  overflow-y: hidden;
  height:90%;
}
.profile-overflow{
  height:25vh;
}

.profile-events{
  box-sizing: border-box;
  height:90%;
  overflow-y:auto;
  /* background-color:pink; */
}

.uppercase{
  text-transform: uppercase;
}
.certification-title{
  text-align:center;
}

.valign-center{
  align-self: center;
}

.br0{
  border-right:0 !important;
}
.bl0{
  border-left:0 !important;
}

.responsiveText{
  font-size: calc(.25rem + 0.75vw);
}