.rev-projects-headers{
  /* grid-template-columns:5% 15% 10% 15% 15% 10% 10% 10% 5% 5%; */
  grid-template-columns: 8ch 15% 15% 10ch 10ch 20% 15% 10%; 
}

.rev-products-headers{
  grid-template-columns: repeat(9, 11.2%);
}

.rev-projects-and-products-headers{
  display: grid;
  /* grid-template-columns: 7% repeat(5,14.5%)10% 5% 5%; */

  /* grid-template-columns: repeat(10, 10%); */
  grid-template-rows: 3em;
  align-items: center;
  border-bottom: 1px solid #DDDDDD;
}

.rev-projects-and-products-headers-top{
  display: grid;
  /* grid-template-columns: 7% repeat(5,14.5%) 10% 5% 5%; */
  /* grid-template-columns: repeat(10, 10%); */
  /* grid-template-columns: repeat(9, 11%); */
  /* grid-template-columns: 11%; */
  grid-template-rows: .75em;
  align-items: center;
  padding-bottom:10px;
  margin:5px 0;
  border-bottom: 1px solid #DDDDDD;
}



.rev-card-container{
  /* width: 700px; */
  /* box-shadow: 1px 1px 3px 3px #737475 */
  /* height: 100%; */
}

.rev-card-header-grey {
  background-color:#0E2746;
  /* padding: .6em 1em .6em 1em; */
  padding: 1em;
}

.lower-text {
  margin-top: 1em;
  font-size: 14px;
}

.small-text{
  font-size: 15px;
  font-weight: bold;
}

.rev-card-header-title-style-gray{
  /* color: #115646; */
  color:#0E2746;
  color:white
  /* font-weight: bold; */
}

.rev-card-body{
  padding: 1em;
  background-color:#EDECE0;
}

.rev-overflow-container{
overflow: auto;
/* min-height: 150px; */
max-height: 40vh;
/* height: 30vh; */
}

.rev-projects-and-products-headers-text-color-grey{
color: #737475;
}

/* .cta-button2 {
padding: .8em;
border: 1px solid #FDA941;
border:1px solid #ffcf34;
background-color: white;
color: #115646;
color:#0E2746;
border-radius: 5%;
text-align: center;
font-weight: bolder;
letter-spacing: 1px;
outline:none;
cursor: pointer;
} */

.remove-text-decoration{
  text-decoration: none;
}

.tooltip {
  position: relative; /* Ensure tooltip is positioned relative to its parent */
  display: inline-block; /* Make sure tooltip wraps around its content */
}

.tooltip .tooltiptext {
  visibility: hidden; /* Hide the tooltip by default */
  width: 120px; /* Set a fixed width for the tooltip */
  background-color: black; /* Tooltip background color */
  color: white; /* Tooltip text color */
  text-align: center; /* Center-align text */
  border-radius: 6px; /* Rounded corners */
  padding: 5px; /* Padding inside the tooltip */
  position: absolute; /* Position the tooltip absolutely */
  z-index: 1; /* Ensure tooltip appears on top of other elements */
  bottom: 100%; /* Position above the tooltip target */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
}

.tooltip:hover .tooltiptext {
  visibility: visible; /* Show the tooltip on hover */
}
