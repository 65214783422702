.coming-soon-page{
  height:90vh;
  width:100%;
  /* background-image: url('https://images.unsplash.com/photo-1542621334-a254cf47733d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'); */
  /* background-color: white; */
  background-repeat: no-repeat;
  background-size: cover;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-shadow:inset 
}
/* .border-left{
  border-left:0.25em solid black;
} */
.not-found-number{
  font-size:500%;
  padding:0.5em;
}
.not-found-text{
  font-size:200%;
  padding:1em;
}