.tabs{
  display: flex;
}

.tab-list {  
  display:flex;
  flex-direction: column;
  /* border-bottom: 1px solid #ccc; */
  padding-left: 0;
  padding-right:1em;
  border-right:1px solid #0E2746;
  /* background-color: aquamarine; */
}

.tab-list-item {
  /* display: inline-block; */
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  /* background-color:antiquewhite */
}

.tab-list-active {
  background-color: #F3F3F3;
  border-radius: 5px;
  /* border: solid #ccc; */
  /* border-width: 1px 1px 0 1px; */
}

.tab-content{
  padding-left:1em;
  max-height:60vh;
  width:80%;
  overflow-y: auto;
}

.tab-content table tbody tr{
  display:flex;
  justify-content: space-between;
}