/* dashboard css */
.my-dashboard{
  display:flex;
  justify-content: space-between; 
  width:100%;
  /* grid-template-columns: repeat(3, 30%); */
}

.my-dashboard-col{ 
  display:flex;
  flex-direction:column;
  width:33%;
} 

.my-dashboard-col .card-body{
  padding:0;
}

.my-membership-table{
  width:100%;
  padding:0.5em;
  text-align:center;
}

.card-body{
  padding:0.5em;
}

.dashboard-input{
  width:100%;
  background-color:transparent;
  margin: 2px 0;
  border:none;
  outline:0;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  /* border:1px solid black; */
  /* padding:5px; */
  word-wrap: break-word;
  word-break: break-all;
  color:black;
  font-family: 'GreycliffCFMedium', Arial, sans-serif;
  font-size:16px;
}

/* loader css */
.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}