.pro-flexbox-col{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pro-flexbox-col-no-sb{
  display: flex;
  flex-direction: column;
}

.pro-flexbox-row-sb{
  display: flex;
  justify-content: space-between;
}

.background-color-white{
  background-color:#EDECE0;
}

.flex-grow-1{
  flex-grow: 1;
  flex-basis: 100px;
}

.pro-margin-1em{
  margin: 1em;
}

.pro-margin-top-1em{
  margin-top:1em;
}

.pro-margin-left-1em{
  margin-left: 1em;
}

.width-100{
  width:100%;
}

.margin-top-and-bottom{
  margin-top: 1em;
  margin-bottom: 1em;
}

.height-100vh{
  height: 100vh;
}

.height-50-vh{
  height: 50vh;
}