@font-face {
  font-family: 'GreycliffCFMedium';
  src: local('GreycliffCFMedium'),
  url('../../fonts/GreycliffCF-Medium.woff');
}

.detailCard-project{
  display:flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
}
.detailCard-project td{
  display:flex;
  justify-content: space-around;
  /* background-color:pink; */
}
.detailProjectHeader{
  display: flex;
  padding:0.5em;
  /* justify-content: flex-start; */
  justify-content: space-between;
  border-bottom:1px solid #0E2746;
  margin-bottom: 1em;
}
.detailProjectHeader > *{
  padding-right:1em;
}
.project-detail-table{
  width:100%;
  font-family: 'GreycliffCFMedium', Arial, sans-serif;
  font-size: 16px;
}
.project-detail-table tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}
.project-detail-table tbody tr td {
  width:50%;
  padding:5px;
  vertical-align: bottom;
}
.project-detail-table > tbody > tr > td:first-child {
  padding-top:10px;
}
.project-detail-input{
  width:100%;
  background-color:transparent;
  border:none;
  /* border:1px solid black; */
  /* padding:5px; */
  word-wrap: break-word;
  word-break: break-all;
  color:black;
  font-family: 'GreycliffCFMedium', Arial, sans-serif;
  font-size:16px;
}
.unit-label{
  /* display:flex; */
  align-self: flex-end;
  font-size:smaller;
}
.red{
  color:red;
}